<template>
	<!--  金刚区 -->
	<div class="ac_banner_jgq flex justify_content_sb">
		<div class="left-img-box img-box">
			<a target="_blank" href="https://www.metaera.hk/CryptoFi"
				><img
					src="https://res.metaera.hk/resources/assets/images/event/ac_top_banner_left_m.png"
			/></a>
		</div>
		<div class="right-img-box img-box">
			<a target="_blank" href="https://www.metaerasummit.com/">
				<img
					src="https://res.metaera.hk/resources/assets/images/event/ac_top_banner_right_m.png"
				/>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return {};
	},
	methods: {},
	mounted() {},
};
</script>

<style scoped>
.ac_banner_jgq {
	margin-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
}
.ac_banner_jgq .img-box {
	border-radius: 6px;
	overflow: hidden;
}
.ac_banner_jgq img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media screen and (max-width: 900px) {
	.ac_banner_jgq {
		width: 90%;
		margin-left: 5%;
	}
	.ac_banner_jgq .img-box {
		width: clamp(100px, calc(100vw * 160 / 375), 580px);
		height: clamp(60px, calc(100vw * 90 / 375), 160px);
	}
}
</style>
